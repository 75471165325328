.modalButton{
    background-color: #ffedcee5 !important;
    color: #1d1502 !important;
    border: 1px solid #e2b64fc1 !important;
    margin-top:5px !important;
}

.modalButton:hover{
    background-color: #E2B74F !important;
    color: #020200 !important;


}


.contactUs{
    width: 100% !important;
    height: 80vh !important;
    border: none !important;
    border-radius: 10px; 
}