.sliderContainerStyling{
  
    background-color: rgba(226,183,79,.25);
    color:#000000;
    border-radius:20px;
    
   
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 25px;
   
    

 
  }
  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }



  .visualContent{
    min-height: 30vh !important;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    
  }

  .sliderImage{
    display: flex !important;
    align-items: center !important;
  }
  .sliderImage > img{
    border-radius: 7px;
    -moz-box-shadow: 0 0 7px rgb(255, 224, 140);
-webkit-box-shadow: 0 0 7px rgb(255, 229, 151);
box-shadow: 0px 0px 7px rgb(255, 232, 156);
max-height: 200px !important;
  }


 

  /* .sliderContents > img{
    border-radius: 10px;
    opacity: 95%;
    margin-bottom: 10%;



  } */
  .artistDetails{
    text-align: center; 
    display: none;
    
  }
  .artistDetailsRow{
   min-height: 10vh;
  }
  .sliderHeader{
 
    text-align: center;
    border-radius: 20px;
    margin-top: 10px !important;
    font-size: 18px !important;
    font-family: 'multicolore' !important;
    color: #ffedcee5 !important;
    text-shadow: 1px -1px .5px rgb(160, 123, 0) !important;
    background: none !important;
    border-radius: 15px !important;
    border: none !important;

  
  }

.segment.artistDetails::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.segment.artistDetails{
    -ms-overflow-style: none !important;  /* Internet Explorer 10+ */
    scrollbar-width: none !important; 
    font-size: 20px;
    color: #f8e6be !important;
    overflow-y: scroll;
    overflow-x: hidden;
    
    line-height: 20px;
    text-align: justify;
    
    text-shadow:1px 1px 0px #000000 !important;
    font-family: 'RobotoSlab' !important;
    background-color: rgba(25, 18, 2, 0.415) !important;
    border-radius:7px !important;
    border: none !important;
    padding:30px !important;
    max-height: 20vh ;
   
   
  
  }
  

.sliderButton{
    background-color: #020202 !important;
    opacity: 88%;
    font-weight: bolder;
    border: 1px solid #E2B74F !important;
    border-radius: 14px !important;
    color: #E2B74F !important;
    margin-top:15px !important;
    margin-bottom: 15px !important;
  }
  .sliderButton:hover{
    background-color: #E2B74F!important;
    color: #f8e6be !important;
  }

  .slick-dots li button {
    border-radius: 20px !important;
    color: #E2B74F !important;
    opacity: 70% !important;
    background-color: #E2B74F !important;
    
  }
  
  .slick-prev, .slick-next {
    border-radius: 20px !important;
    
  }
  .slick-prev:before, .slick-next:before {
  
    color: #E2B74F !important;
  
  }



  @media only screen and (max-width: 720px){

     .segment.artistDetails{
     /* display: none; */
     font-size: 15px;
     

    } 
    .sliderGrid{
      margin-left:-2.25% !important;
      overflow-y: hidden;
      width:80vmin!important;
      }
     
    /* .nft34156852{
      position: relative;
      right:2.5%;
      digitalLotus was positioned right for some reason, should probably update to mobile only
      } */
  
   
   
   }

   .sliderGrid{
    margin-left:-1.5% !important;
    
    overflow-y: hidden;
    }

   
   @media only screen and (min-width: 1000px){

    .segment.artistDetails{
    /* display: none; */
    font-size: 25px;
    line-height: 30px;
    

   } 
 
  
  
  }