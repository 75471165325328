.onCyberGallery{
    height: 80vh;
    width: 100%;
    margin-top: 20px;
    border-radius: 20px;
    border: none !important;
}

.blackGButton{
    background-color: #020202 !important;
    opacity: 88%;
    border: 1px solid rgba(255, 201, 75, 0.5) !important;
    border-radius: 7px;
    color: #f8e6be !important;
    min-width: 40px;
    margin-bottom: 10px !important;
}
.blackGButton:hover {
    background-color: #E2B74F!important;
    color: #050402 !important;
}
//    height:"80vh"; width: "100%", marginTop: "20px"
