
.blackButton{

  background-color: #020202 !important;
  opacity: 88%;
  border: 1px solid rgba(255, 201, 75, 0.5) !important;
  border-radius: 7px;
  color: #f8e6be !important;
  min-width: 40px;
 
  

}

.blackButton:hover{
  background-color: #E2B74F!important;
  color: #050402 !important;
}

.blackButton > i{
   
    font-size: 15px;
   
    text-align: center !important;
}

.socialButtons{
  margin-bottom: 15px;
}

.theButtons{
  text-align: center;
}

@media only screen and (max-width: 720px){

 
}