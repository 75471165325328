.curationProjectCopy{
    color: #E2B74F !important;
    /* text-shadow: -0.75px 0.5px 0.5px #f6f1e4; */
    text-align: center;
    text-shadow: none;
    letter-spacing: 2px;
    font-family: 'multicolore' !important;
    font-size: 24px !important;
  
  
  }
  .sub.curationProjectCopy{
    color: #f8e6be!important;
    font-family: 'RobotoSlab' !important;
    text-transform: none !important;
    font-size: 14px !important;
  }
  h2.curationProjectCopy{
    
    letter-spacing: 7.77px !important;
  }

  .curatedByNclyneSegment{
    background-color: rgba(0, 0, 0, 0.89) !important;
    border-radius: 20px !important;
    
    border: 1px solid rgba(255, 201, 75, 0.5) !important;
    margin-bottom: 10px !important;
    
  }

  .segmentImage{
    border-radius: 15px !important;
    display: inline-block !important;
    max-height: 600px;
  }
  