
.blackHomeButton{

    background-color: #000000 !important;
    opacity: 87%;
    border: .5px solid rgba(255, 201, 75, 0.25) !important;
    border-radius: 17px !important;
    color: rgba(255, 201, 75, 0.62) !important;
    min-width: 40px;
    font-size: 21px!important;
    margin-top: 10px !important;
    font-family: 'Varela Round' !important;
    
   
    
  
  }
  .blackHomeButton:hover{
    background-color: #E2B74F!important;
    color: #050402 !important;
  }

  .homeContainer{
    background-color: #785c008c;
    padding-top:  20px !important;
    padding-bottom:  50px !important;
    border-radius: 10px;
    background-image: url(../../../imgs/mtnbgfinal.png);
    background-position: center;
    opacity: 98%;

  }

  .homeCopy{
    text-align: left;
    background-color: #0b0801f3 !important;
    opacity: 87%;
    border-radius: 10px !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    margin-top: 20px !important;
    margin-bottom: 50px !important;

  }

  .homeCopy > div {
    color: #ebd5adc0 !important;
    letter-spacing: 2.25px;
    line-height: 25px !important;
    font-family: 'Varela Round', sans-serif !important;
    // font-family: 'RobotoSlab' !important;
    font-style: italic;
    font-size: 18px !important;
    text-shadow: #050402 2px 2px 2px;

  }